<template>
	<div class="page questionDetail_page">
		<audioCom ref="audioCom"></audioCom>
		<div class="w1200">
			<div class="questionDetail_typebox" @click="sysgoback">
				<img class="icon" src="../../assets/static/leftgo.png"/>
				<div class="text fontsize16">返回题库</div>
			</div>
			<div class="qdtop_box">
				<img class="bgimg" src="../../assets/static/image66.png"/>
				<div class="box1">
					<div class="text1 fontsize20">{{questionobj.title}}</div>
					<div class="text2 fontsize14" @click="scrollToBottom()" v-if="classify==0&&typeindex==0">提交答案</div>
					<div class="text2 fontsize14" @click="handleType(0)" v-if="classify==0&&typeindex==1&&type=='ques'">返回题目</div>
					<div class="text2 fontsize14" @click="handleType(0)" v-if="typeindex==2&&type=='ques'">返回题目</div>
				</div>
				<div class="box2" v-if="classify==0">
					<div class="itemobj">
						<div class="text1 fontsize16">提交</div>
						<div class="text2 fontsize20">{{$util.oneToqian(tongjiobj.total+tongjiobj.memNum)}}</div>
					</div>
					<div class="itemobj">
						<div class="text1 fontsize16">通过</div>
						<div class="text2 fontsize20">{{$util.oneToqian(tongjiobj.success+tongjiobj.successNum)}}</div>
					</div>
					<div class="itemobj">
						<div class="text1 fontsize16">时间限制</div>
						<div class="text2 fontsize20">{{questionobj.cpuLimit}}s</div>
					</div>
					<div class="itemobj">
						<div class="text1 fontsize16">内存限制</div>
						<div class="text2 fontsize20">{{$util.kbToMB(questionobj.memoryLimit)}}</div>
					</div>
				</div>
			</div>
			<div class="block20"></div>
			<div class="questionDetail_box" v-if="typeindex==0">
				<!-- 编程题目 -->
				<div class="leftbox" v-if="classify==0">
					<div class="box1">
						<div class="typediv">
							<div class="line"></div>
							<div class="text fontsize20">题目描述</div>
						</div>
						<!-- <div class="descbox ql-container ql-snow fontsize16">
							<div class="ql-editor">
								<div v-html="questionobj.contents"></div>
							</div>
						</div> -->
						<div class="descbox fontsize16">
							<div class="ql-editor">
								<div v-html="questionobj.contents"></div>
							</div>
						</div>
					</div>
					<div class="block20"></div>
					<div class="box2">
						<div class="flexrow flexbetween">
							<div class="typediv">
								<div class="line"></div>
								<div class="text fontsize20">提交代码</div>
							</div>
							<div class="selbox">
								<el-select v-model="selvalue" placeholder="请选择" size="medium">
								    <el-option label="C++" value="C++"></el-option>
								</el-select>
							</div>
						</div>
						<div class="textareabox fontsize14">
							<!-- <textarea wrap="soft" placeholder="请在此输入"></textarea> -->
							<codemirror
							    ref="mycode"
							    v-model="curCode"
							    :options="cmOptions"
							    class="codemirror-container">
							</codemirror>
						</div>
						<div class="flexrow flexbetween">
							<div></div>
							<div class="btn1 fontsize14" @click="handleceping">提交测评</div>
						</div>
					</div>
				</div>
				<!-- 选择题目 -->
				<div class="leftbox" v-if="classify==1">
					<div class="box3">
						<div class="typediv">
							<div class="line"></div>
							<div class="text fontsize20" v-html="questionobj.contents"></div>
						</div>
						<div class="itemlist">
							<div class="itemobj" v-for="(item,index) in options" :key="index" @click="handleselanswer(item.key)">
								<div class="imgbox">
									<img v-if="userSel!=item.key" class="img100" src="../../assets/static/circle.png"/>
									<img v-if="userSel==item.key" class="img100" src="../../assets/static/check-circle-filled.png"/>
								</div>
								<div class="text fontsize16">{{item.key}}.{{item.optionName}}</div>
							</div>
						</div>
						<div class="btn1 fontsize14" v-if="!correctSel" @click="handlepullanswer">提交答案</div>
						<div class="resulebox" v-if="correctSel">
							<div class="text_dui fontsize20" v-if="correctSel==userSel">选择正确</div>
							<div class="text_cuo fontsize20" v-if="correctSel!=userSel">选择错误</div>
							<div class="text_res fontsize16">正确答案：{{correctSel}}</div>
						</div>
					</div>
					<div class="block20"></div>
				</div>
				<div class="rightbox">
					<div class="box1 fontsize16">
						<div class="item1">
							<div class="text1">题目提供者</div>
							<div class="text2">{{questionobj.authorInfo || "匿名"}}</div>
						</div>
						<div class="item1">
							<div class="text1">难度</div>
							<div class="text3">{{difficultyList[questionobj.difficulty]}}</div>
						</div>
						<div class="item1">
							<div class="text1">
								<div class="item2" @click="handletijie">
									<img class="icon" src="../../assets/static/Frame2101.png"/>
									<div class="text">查看题解</div>
								</div>
							</div>
							<div class="text3" v-if="questionobj.recordStatus">已通过</div>
							<div class="text3" v-if="!questionobj.recordStatus">未通过</div>
						</div>
					</div>
					<div class="block20" v-if="questRecordList.length>0"></div>
					<div class="box1 fontsize16" v-if="questRecordList.length>0">
					    <div class="item1"><div class="text2">提交记录</div></div>
						<div class="item1 pointer" v-for="(item,index) in questRecordList" :key="index" @click="myalert(item.answer || item.userQuestCode)">
							<div class="text2">{{item.createDate}}</div>
							<div class="text3" v-if="item.recordStatus==0">已通过</div>
							<div class="text3" v-if="item.recordStatus==1">未通过</div>
						</div>
					</div>
					<div class="block20" v-if="infoTags.length>0"></div>
					<div class="box2" v-if="infoTags.length>0">
						<div class="titlename fontsize16">标签</div>
						<div class="tagbox fontsize14">
							<el-tag :type="tagcolor[Math.floor(Math.random() * 3)]" v-for="(tagitem,tagindex) in infoTags" :key="tagindex">{{tagFormat(tagitem)}}</el-tag>
						</div>
					</div>
					<div class="block20" v-if="lastHlQuestInfo || nextHlQuestInfo"></div>
					<div class="box3" v-if="lastHlQuestInfo || nextHlQuestInfo">
						<div class="titlename fontsize16">推荐题目</div>
						<div class="datalist fontsize16">
							<div class="itemobj" v-if="lastHlQuestInfo">
								<div class="text0">上一题</div>
								<div class="text2 clamp" @click="reshreshques(lastHlQuestInfo.id)">{{lastHlQuestInfo.title}}</div>
							</div>
							<div class="itemobj" v-if="nextHlQuestInfo">
								<div class="text0">下一题</div>
								<div class="text2 clamp" @click="reshreshques(nextHlQuestInfo.id)">{{nextHlQuestInfo.title}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 测评结果 -->
			<div class="questionDetail_box1" v-if="typeindex==1">
				<div class="leftbox">
					<div class="box">
						<div class="typebox fontsize16">
							<div class="itemobj" :class="{'active':cptypeindex==0}" @click="handlecpType(0)">
								<div class="text">编译信息</div>
								<div class="line"></div>
							</div>
							<div class="itemobj" :class="{'active':cptypeindex==1}" @click="handlecpType(1)">
								<div class="text">源代码</div>
								<div class="line"></div>
							</div>
						</div>
					</div>
					<div class="block20"></div>
					<div class="box1">
						<div class="typediv">
							<div class="line"></div>
							<div class="text fontsize20" v-if="cptypeindex==0">编译信息</div>
							<div class="text fontsize20" v-if="cptypeindex==1">源代码</div>
						</div>
						<!-- <div class="descbox fontsize16" v-if="cptypeindex==0">
							<p v-if="resultsobj.exitStatus==0">编译成功</p>
							<p v-if="resultsobj.exitStatus==0&&resultsobj.files.stdout">编译成功结果：</span>
							<p v-if="resultsobj.exitStatus==0&&resultsobj.files.stdout">{{resultsobj.files.stdout}}</span>
							<p v-if="resultsobj.exitStatus==1">编译失败</p>
							<p v-if="resultsobj.exitStatus==1&&resultsobj.files.stderr">编译失败结果：</span>
							<p v-if="resultsobj.exitStatus==1&&resultsobj.files.stderr">{{resultsobj.files.stdout}}</span>
							<p v-if="resultsobj.exitStatus==2">编译超时</p>
							<p>题目输出值：{{questionobj.questOut}}</p>
						</div> -->
						<div class="descbox fontsize16" v-if="cptypeindex==0">
							<div class="w100" style="margin-bottom: 10px;" v-for="(item,index) in resultsList" :key="index">
								<el-tooltip class="item" effect="dark" :content="item.restip" placement="top">
								    <el-alert v-if="item.recordStatus==0" :title="item.restext" type="success" show-icon :closable="false"></el-alert>
								    <el-alert v-else :title="item.restext" type="error" show-icon :closable="false"></el-alert>
								</el-tooltip>
							</div>
						</div>
						<div class="textareabox fontsize14" v-if="cptypeindex==1">
							<!-- <textarea wrap="soft" placeholder="请在此输入"></textarea> -->
							<div class="text" v-text="cepingobj.code"></div>
						</div>
					</div>
					
				</div>
				<div class="rightbox">
					<div class="box1 fontsize16">
						<div class="item">
							<img class="avatar" :src="userInfo.hlImage"/>
							<div class="text fontsize16">{{userInfo.nikename}}</div>
						</div>
						<div class="item1">
							<div class="text1">所属题目</div>
							<div class="text4">{{questionobj.title}}</div>
						</div>
						<div class="item1">
							<div class="text1">评测状态</div>
							<div class="text3" v-if="answerRecord.recordStatus==0">Correct answer</div>
							<div class="texterror" v-if="answerRecord.recordStatus==1">Wrong anwser</div>
						</div>
						<div class="item1" v-if="answerRecord">
							<div class="text1">编译比对</div>
							<div class="text3" v-if="answerRecord.recordStatus==0">比对正确</div>
							<div class="texterror" v-if="answerRecord.recordStatus==1">比对错误</div>
						</div>
						<div class="item1" v-if="answerRecord">
							<div class="text1">评测分数</div>
							<div class="text3">{{answerRecord.score || 0}}</div>
						</div>
						<div class="item1">
							<div class="text1">提交时间</div>
							<div class="text2">{{cepingobj.time}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 查看题解 -->
			<div class="questionDetail_box" v-if="typeindex==2">
				<div class="leftbox">
					<div class="bottombox_res">
						<div class="typebox">
							<div class="line"></div>
							<div class="text fontsize20">题解信息</div>
						</div>
						<div class="videodiv" v-if="quesProblemobj.videoUrl">
							<video class="video" :src="quesProblemobj.videoUrl" controls controlslist="nodownload"></video>
						</div>
						<div class="textbox fontsize206">
							<div class="text" v-html="quesProblemobj.contents"></div>
						</div>
						<div class="block20"></div>
						<div class="typebox" v-if="quesProblemobj.description&&questionobj.classify==0">
							<div class="line"></div>
							<div class="text fontsize20">参考代码</div>
						</div>
						<div class="textareabox fontsize14" v-if="quesProblemobj.description&&questionobj.classify==0">
							<div class="text" v-text="quesProblemobj.description"></div>
						</div>
					</div>
				</div>
				<div class="rightbox">
					<div class="box1 fontsize16">
						<div class="item1">
							<div class="text1">题目提供者</div>
							<div class="text2">{{questionobj.authorInfo || "匿名"}}</div>
						</div>
						<div class="item1">
							<div class="text1">难度</div>
							<div class="text3">{{difficultyList[questionobj.difficulty]}}</div>
						</div>
						<div class="item2" @click="handleType(0)">
							<img class="icon" src="../../assets/static/Frame2101.png"/>
							<div class="text">返回题目</div>
						</div>
					</div>
					<div class="block20" v-if="infoTags.length>0"></div>
					<div class="box2" v-if="infoTags.length>0">
						<div class="titlename fontsize16">标签</div>
						<div class="tagbox fontsize14">
							<el-tag :type="tagcolor[Math.floor(Math.random() * 3)]" v-for="(tagitem,tagindex) in infoTags" :key="tagindex">{{tagFormat(tagitem)}}</el-tag>
						</div>
					</div>
					<!-- <div class="block20"></div>
					<div class="box3">
						<div class="titlename fontsize16">推荐题目</div>
						<div class="datalist fontsize14">
							<div class="itemobj">
								<div class="text1">-</div>
								<div class="text2">P003 [NOIP2002 普及组]过河卒</div>
							</div>
							<div class="itemobj">
								<div class="text1">-</div>
								<div class="text2">P003 [NOIP2002 普及组]过河卒</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>
			
		</div>
		<div class="block30"></div>
		<!-- 弹窗 -->
		<el-dialog title="" center width="480px" top="18vh" :visible.sync="dialogresult" :close-on-click-modal="false" @close="closedialogresult">
			<div class="resultbox1" v-if="resultstate==2">
				<div class="imgbox"><img class="img100" src="../../assets/static/cuoicon.png"/></div>
				<div class="text1 fontsize20">提交失败</div>
				<div class="text2 fontsize16">代码过短或编译失败，重新输入</div>
				<el-button style="margin-top: 24px;" type="primary" @click="closedialogresult">重新输入</el-button>
			</div>
			<div class="resultbox1" v-if="resultstate==1">
				<div class="imgbox"><img class="img100" src="../../assets/static/duiicon.png"/></div>
				<div class="text1 fontsize20">提交成功</div>
				<div class="text2 fontsize16"></div>
			</div>
		</el-dialog>
		<!-- 弹窗测评成功 -->
		<div class="cp_success_box" v-if="successshow" @click="closesuccessshow">
			<div class="cp_mask"></div>
			<div class="imgbox">
				<img class="img100" src="../../assets/static/Group3465688.png"/>
			</div>
		</div>
		<el-dialog title="我提交的信息" width="488px" top="5vh" :visible.sync="showtexttc" :close-on-click-modal="false" @close="closedialogresult">
			<div class="fontsize16" style="white-space: pre;">
				<div class="text" v-text="showtextCon"></div>
			</div>
			<div slot="footer" class="dialog-footer" style="margin-top: 28px;">
			    <el-button @click="showtexttc = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
import { codemirror } from 'vue-codemirror'
// import 'vue-codemirror/node_modules/codemirror/mode/clike/clike.js';
import '../../common/codesetting.js';
export default {
	components:{
	    codemirror
	},
	data() {
		return {
			uid:"",//题目id
			lastHlQuestInfo:null,//上一提
			nextHlQuestInfo:null,//下一提
			type:"ques",//来源 题库ques class match
			classify:100,//分类 0编程 1选择
			questionobj:{
				difficulty:0
			},//题目对象
			options:[],//选项
			infoTags:[],//题目类型
			tagcolor:["","success","danger",'warning'],//颜色
			userSel:"",//用户选择
			correctSel:"",//正确选择
			selvalue:"C++",
			dialogresult:false,//结果
			resultstate:0,//0是没用，1是正确，2是错误
			typeindex:0,//0题目，1是测评结果,2是题解
			cptypeindex:0,//0测试情况，1源代码
			successshow:false,//测评成功弹窗
			curCode:'',//代码输入值
			cmOptions:{
			    mode:'text/x-c++src',
			    theme: "base16-light",
				styleActiveLine: true, // 高亮选中行
				lineNumbers: true, // 显示行号
				lineWrapping: true // 启用自动换行
			},
			resultsobj:{
				exitStatus:200,//0成功，1失败，2超时
			},//测评结果
			resultsList:[],//测评结果集合
			answerRecord:null,//答题记录
			quesProblemobj:{},//题解对象
			tongjiobj:{
				success: 0,
				total: 0
			},//统计情况
			questRecordList:[],//答题记录
			showtexttc:false,//弹窗显示我的输入代码
			showtextCon:"",//弹窗显示我的输入代码
		};
	},
	mounted: function() {
		if(!this.isLogin){
			this.$message.error("请先返回首页登录！")
			return false;
		}
		if(this.$route.query&&this.$route.query.uid){
			this.uid = this.$route.query.uid
			if(this.$route.query.type){
				this.type = this.$route.query.type
			}
			//获取题目类型
			this.gethlQuestType()
		}else{
			this.$message.error("参数丢失，请返回")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','cepingobj','difficultyList']),
	},
	methods: {
		...mapMutations(["SET_cepingobj"]),
		//提交的信息
		myalert(text){
			this.showtextCon = text //弹窗显示我的输入代码
			this.showtexttc = true //弹窗显示我的输入代码
			// text = text.replace(/\n/g, '<br>');
			// this.$alert(sanitizedContent, '我提交的信息', {
			//     confirmButtonText: '确定',
			// 	dangerouslyUseHTMLString: true,
			//     callback: action => {}
			// });
		},
		//查询答题记录
		gethlQuestRecord(){
			var _this = this
			var params = {
				questNo: this.questionobj.questNo,
				userUuid:this.userInfo.uuid
			}
			this.$http.post('app/hlexam/hlQuestRecord/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var  records = res.data.records
					const obj = records.find((ifitem) => { //这里的options就是上面遍历的数据源
						return ifitem.recordStatus == 0; //正确状态 0正确 1错误
					});
					if(obj){
						_this.questionobj["recordStatus"]  = true
					}else{
						_this.questionobj["recordStatus"]  = false
					}
					_this.questRecordList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取题目答题统计情况
		gettjtotal(){
			var _this = this 
			var params = {
				id: this.uid,
			}
			this.$http.post('app/hlexam/hlQuestRecord/total', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					if(res.data){
						_this.tongjiobj = res.data
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//选择题目题解答案
		handlepullanswer(){
			var _this = this
			var params = {
				keys: "",
				questNo: this.questionobj.questNo,
				userUuid: this.userInfo.uuid
			}
			if(this.classify==1){
				if(!this.userSel){
					this.$message.error("请先选择答案选项！")
					return false;
				}
				params.keys = this.userSel
				
				showLoading()
				this.$http.post('app/hlexam/hlQuestRecord/save', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						_this.$message.success("提交成功！")
						_this.correctSel = _this.questionobj.answer
						if(_this.correctSel==_this.userSel){
							_this.$refs.audioCom.playAudioCom()
						}
						//查询答题记录
						_this.gethlQuestRecord()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//选择题选择答案
		handleselanswer(key){
			this.userSel = key
		},
		//查看题解
		handletijie(){
			var _this = this
			if(this.questionobj.exerAllOpen==1&&this.userInfo.level==0){
				this.$alert('抱歉，您没有查看这个题解的权限！', '提示', {
				    confirmButtonText: '确定',
				});
				return false;
			}
			if(this.userInfo.level&&this.userInfo.level==1){
				var params = {
					currentPage: 1,
					pageSize: 1,
					questNo:this.questionobj.questNo
				}
				showLoading("加载题解中...")
				this.$http.post('app/hlexam/hlQuestExer/index', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						if(res.data.records.length>0){
							_this.quesProblemobj = res.data.records[0]
							_this.handleType(2)
							_this.$message.success("题解加载成功！")
						}else{
							_this.$alert('抱歉，此题目没题解！', '提示', {
							    confirmButtonText: '确定',
							    callback: action => {
							        
							    }
							});
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}else{
				this.$alert('查看题解需要成为VIP用户！', '提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				        
				    }
				});
			}
		},
		//获取题目类型
		gethlQuestType(){
			var _this = this 
			var params = {
				currentPage: 1,
				pageSize: 888
			}
			this.$http.post('app/hlexam/hlQuestType/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.questypeList = res.data.records
					//获取题目详情
					_this.gethlQuestInfo()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//标签
		tagFormat(tag){
			const obj = this.questypeList.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.typeCode == tag; //筛选出匹配数据
			});
			if(obj){
				return obj.title
			}else{
				return tag
			}
		},
		//上一题下一题
		reshreshques(uid){
			this.lastHlQuestInfo = null
			this.nextHlQuestInfo =  null
			this.uid = uid
			//获取题目
			this.gethlQuestInfo()
		},
		//获取题目
		gethlQuestInfo(){
			var _this = this 
			var params = {}
			var trainformData = localStorage.getItem("trainformData")
			if(trainformData){
				params = JSON.parse(trainformData)
			}
			params["id"] = this.uid
			showLoading()
			//查询内容
			this.$http.post('app/hlexam/hlQuestInfo/detailNext', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					var hlQuestInfo = res.data.hlQuestInfo
					_this.classify = hlQuestInfo.classify
					if(hlQuestInfo){
						//获取类型json
						try{
							_this.options = JSON.parse(hlQuestInfo.options)
						}catch(e){
							_this.options = []
						}
						try{
							_this.infoTags = JSON.parse(hlQuestInfo.infoTags)
						}catch(e){
							_this.infoTags = []
						}
						_this.questionobj = hlQuestInfo
						if(res.data.lastHlQuestInfo){
							_this.lastHlQuestInfo =  res.data.lastHlQuestInfo
						}
						if(res.data.nextHlQuestInfo){
							_this.nextHlQuestInfo =  res.data.nextHlQuestInfo
						}
						//获取题目答题统计情况
						_this.gettjtotal()
						//查询答题记录
						_this.gethlQuestRecord()
					}else{
						_this.$alert('题目不存在，请返回重试', '提示', {
						    confirmButtonText: '确定',
						    callback: action => {
								_this.sysgoback()
						    }
						});
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//下标
		handleType(type){
			this.typeindex = type
		},
		//测评结果分类型
		handlecpType(type){
			this.cptypeindex = type
		},
		//测评成功
		closesuccessshow(){
			this.successshow = false
		},
		//关闭弹窗
		closedialogresult(){
			this.dialogresult = false
			if(this.resultstate==1){
				//提交成功
				this.resultstate = 0
				this.handleType(1)
				// 0成功 1失败 2超时
				if(this.answerRecord.recordStatus==0){
					this.successshow = true
				}else{
					this.successshow = false
				}
			}else{
				this.resultstate = 0
			}
		},
		//提交测评
		handleceping(){
			// console.log(this.curCode)
			var _this = this
			if(this.curCode){
				var params = {
					codeContent: this.curCode,
					questNo: this.questionobj.questNo
				}
				showLoading()
				this.$http.post('app/hlexam/test/testCode', params).then(function(res) {
					//数据处理
					hideLoading()
					if (res.code == 200) {
						if(res.data){
							//测评鸡返回结果
							_this.resultsList = res.data
							_this.cptypeindex = 0
							//保存对象
							var time = _this.$util.formatdateTime(new Date())
							//console.log({time:time,code:this.curCode})
							_this.SET_cepingobj({time:time,code:_this.curCode})
							var params = {
								questNo: _this.questionobj.questNo,
								userQuestCode: _this.curCode,//用户输入代码
								questTestResponseList:_this.resultsList,
								userUuid: _this.userInfo.uuid
							}
							showLoading()
							_this.$http.post('app/hlexam/hlQuestRecord/save', params).then(function(res) {
								//数据处理
								hideLoading()
								if (res.code == 200) {
									//_this.$message.success("提交成功！")
									//处理逻辑
									var cornum = 0 //正确数量
									_this.resultsList.forEach((resitem, resindex) => {
										const neicun = _this.$util.kbToMB(resitem.testResult.memory / 1024)
										const time = (resitem.testResult.time / 1000000).toFixed(2) + "ms"
										var status = resitem.testResult.status
										if(res.data.recordStatus==1){
											status = 'Wrong anwser'
										}
										const fenshu = resitem.recordStatus==0?100:0
										resitem["restip"] = "输出结果：\n" + (resitem.testResult.files.stdout || resitem.testResult.files.stdout)
										resitem["restext"] = '测试点'+(resindex+1)+'：运行内存['+neicun+']，运行时间['+time+']，评测状态['+status+']，分数['+fenshu+']'
										//正确数量
										if(resitem.recordStatus==0){
											cornum ++
										}
									});
									if(_this.resultsList.length>0){
										res.data["score"] = Number(((cornum / _this.resultsList.length)  * 100).toFixed(0));
									}else{
										res.data["score"] = 0
									}
									//正确
									if(cornum>0){
										_this.$refs.audioCom.playAudioCom()
									}
									_this.answerRecord = res.data
									_this.resultstate = 1
									_this.dialogresult = true
									//关闭弹窗
									setTimeout(function(){
										_this.closedialogresult()
									},1000)
									//查询答题记录
									_this.gethlQuestRecord()
								} else {
									_this.$confirm(res.message, '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {}).catch(() => {});
								}
							})
						}else{
							this.dialogresult = true
							this.resultstate = 2
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}else{
				this.dialogresult = true
				this.resultstate = 2
			}
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
